import MenuButton from '../lib/MenuButton'

export default node => {
  const menuPopupName = node.dataset.menuPopup
  const containerNode = global.document.querySelector('[data-menu-popup-container=' + menuPopupName + ']')
  const controlsNode = global.document.querySelector('[data-menu-popup-controls=' + menuPopupName + ']')

  const menuButton = new MenuButton({
    domNode: node,
    popupMenu: controlsNode,
    containerNode
  })
  menuButton.init()
}
