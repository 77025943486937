import { toggleClass, removeClass } from '../lib/utils/sprinkles'
import { hasParent, onKey } from '../lib/utils/dom'
import KEY from '../lib/constants/key'

const toggleAria = target => {
  target.ariaExpanded = target.ariaExpanded === 'true' ? 'false' : 'true'
}

const setAriaExpanded = (target, val) => {
  target.ariaExpanded = val
}

const getDatasetValues = dataset => {
  return {
    toggableName: dataset.toggableSwitch || dataset.toggableSwitchReset,
    toggableEvent: dataset.toggableEvent || 'click',
    toggableClass: dataset.toggableClass || 'isActive'
  }
}

export default node => {
  const toggableSwitchElements = node.querySelectorAll('[data-toggable-switch]')
  const toggableSwitchResetElements = node.querySelectorAll('[data-toggable-switch-reset]')

  const resetSwitchCollection = Array.from(toggableSwitchResetElements)
  const toggableSwitchCollection = Array.from(toggableSwitchElements)

  toggableSwitchCollection.forEach(toggableSwitch => {
    const { toggableName, toggableEvent, toggableClass} = getDatasetValues(toggableSwitch.dataset)
    const toggableContent = node.querySelector('[data-toggable-content=' + toggableName + ']')

    toggableSwitch.addEventListener(toggableEvent, e => {
      if (!hasParent(e.target, toggableContent)) {
        toggleAria(e.target)
        toggleClass(toggableClass, toggableSwitch, toggableContent)
      }
    })

    toggableSwitch.addEventListener('keydown', e => {
      onKey(e, KEY.SPACE, () => toggableSwitch.click())
      onKey(e, KEY.RETURN, () => toggableSwitch.click())
    })
  })

  resetSwitchCollection.forEach(resetSwitch => {
    const { toggableName, toggableEvent, toggableClass} = getDatasetValues(resetSwitch.dataset)
    const toggableContent = node.querySelector('[data-toggable-content=' + toggableName + ']')

    resetSwitch.addEventListener(toggableEvent, () => {
      toggableSwitchCollection.forEach(toggableSwitch => {
        setAriaExpanded(toggableSwitch, false)
        removeClass(toggableClass, toggableSwitch, toggableContent)
      })
    })

    resetSwitch.addEventListener('keydown', e => {
      onKey(e, KEY.SPACE, () => resetSwitch.click())
      onKey(e, KEY.RETURN, () => resetSwitch.click())
    })
  })
}
