export default node => {
  const className = node.dataset.className || 'isStickyActive'
  const rootNode = document.querySelector(node.dataset.root) || global
  let nodeOffsetTop = node.offsetTop

  rootNode.addEventListener('scroll', () => {
    const scrollGlobalTop = global.pageYOffset
    const scrollTop = rootNode.scrollTop
    const stickyHeight = node.dataset.stickyHeight || 0

    const isSticky = rootNode.pageYOffset
      ? nodeOffsetTop <= scrollGlobalTop + parseInt(stickyHeight, 10)
      : scrollTop > nodeOffsetTop + parseInt(stickyHeight, 10)

    if (isSticky) {
      node.classList.add(className)
    } else {
      node.classList.remove(className)
    }
  })
}
