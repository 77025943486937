import { actsAsLinkClick, actsAsLinkMiddleClick, onKey } from '../lib/utils/dom'
import KEY from '../lib/constants/key'

const handleKeydown = link => event => {
  onKey(event, KEY.RETURN, () => actsAsLinkClick(link)(event))
}

export default node => {
  const { linkElementHref } = node.dataset

  node.setAttribute('role', 'link')
  node.addEventListener('click', actsAsLinkClick(linkElementHref))
  node.addEventListener('mousedown', actsAsLinkMiddleClick(linkElementHref))
  node.addEventListener('keydown', handleKeydown(linkElementHref))
}
