/* eslint no-shadow: 0 */
/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */

import { transport } from '../lib/services'
import { toggleClass } from '../lib/utils/sprinkles'
import { isDefined } from '../lib/utils/common'

const deleteContactFromFavorite = (element, contactId) => {
  return transport.ManageContacts.removeContactFromFavorite(contactId).then(response => {
    if(!response.success) {
      return
    }
    element.dataset.method = 'create'

    toggleClass('isActive', element)
  })
}

const addContactToFavorite = (element, contactId) => {
  return transport.ManageContacts.addContactToFavorite(contactId).then(response => {
    if(isDefined(response.success) && !response.success) {
      return
    }
    element.dataset.method = 'delete'

    toggleClass('isActive', element)
  })
}

export default node => {
  const addContactToFavoriteButton = node.querySelector('[data-add-contact-to-favorite-button]')

  addContactToFavoriteButton.addEventListener('click', e => {
    e.preventDefault()
    e.stopPropagation()

    const { contactId, method } = addContactToFavoriteButton.dataset

    if (method === 'delete') {
      deleteContactFromFavorite(addContactToFavoriteButton, contactId)
    } else if (method === 'create') {
      addContactToFavorite(addContactToFavoriteButton, contactId)
    }
  })
}
