const toggleTab = (node, currentView, isActive) => {
  const tabName = currentView.dataset.simpleTabsSwitch
  const tabContent = node.querySelector('[data-simple-tabs-content=' + tabName + ']')

  if (isActive) {
    currentView.classList.add('isActive')
    tabContent.classList.add('isActive')
  } else {
    currentView.classList.remove('isActive')
    tabContent.classList.remove('isActive')
  }
}

export default node => {
  const tabSwitchElements = Array.from(node.querySelectorAll('[data-simple-tabs-switch]'))

  tabSwitchElements.forEach(tabSwitch => {
    tabSwitch.addEventListener('click', () => {
      tabSwitchElements.forEach(element => {
        if (element !== tabSwitch) {
          toggleTab(node, element, false)
        } else {
          toggleTab(node, element, true)
        }
      })
    })
  })
}
