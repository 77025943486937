/* eslint camelcase: 0 */

import { analytics } from '../lib/services'

export default node => {
  const analyticsEvent = node.dataset.analyticsEvent || 'click'
  const eventAction = node.dataset.eventAction
  const eventCategory = node.dataset.eventCategory
  const eventLabel = node.dataset.eventLabel
  const eventValue = node.dataset.eventValue

  node.addEventListener(analyticsEvent, () => {
    analytics.pushEvent('custom_event', {
      event_action: eventAction,
      event_category: eventCategory,
      event_label: eventLabel,
      event_non_interaction: false,
      event_value: eventValue
    })
  })
}
