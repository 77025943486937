import { globalScrollPosition } from '../lib/utils/dom'

export default node => {
  const content = Array.from(node.querySelectorAll('[data-toggable-text-content]'))
  const toggleButton = node.querySelector('[data-toggable-text-button]')

  if (!toggleButton) {
    return
  }

  const currentTextButton = toggleButton.textContent
  const nextTextButton = toggleButton.dataset.toggableTextButtonContent
  let isOpen = false
  let pageScrollTop = 0


  toggleButton.addEventListener('click', e => {
    e.stopPropagation()
    e.preventDefault()

    if(isOpen) {
      global.scrollTo({ top: pageScrollTop })
    } else {
      pageScrollTop = globalScrollPosition().y
    }

    content.forEach(el => {
      el.classList.toggle('isHidden')
    })

    isOpen = !isOpen
    toggleButton.textContent = isOpen ? nextTextButton : currentTextButton
    toggleButton.setAttribute('aria-pressed', isOpen ? 'true' : 'false')
  })
}
