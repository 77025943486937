import Sprinkles from '../src/sprinkles'

const eventName = global.Turbolinks ? 'turbolinks:load' : 'DOMContentLoaded'

let cleanupCallbacks

document.addEventListener(eventName, () => {
  cleanupCallbacks = []
  Array.from(document.querySelectorAll('[data-sprinkle]'))
    .forEach(node => {
      const sprinkles = node.dataset.sprinkle.split(/\s/)

      sprinkles.forEach(name => {
        const sprinkle = Sprinkles[name]

        if (sprinkle) {
          cleanupCallbacks.push(sprinkle.call(null, node))
        }
      })
    })
})

document.addEventListener('turbolinks:before-cache', () => {
  cleanupCallbacks.forEach(callback => {
    if (callback instanceof Function) {
      callback()
    }
  })
})
