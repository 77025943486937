export default node => {
  const className = node.dataset.className || 'isStickyActive'
  const observerNode = document.querySelector(node.dataset.observerNode)

  const cb = entries => {
    if (entries[0].isIntersecting) {
      node.classList.remove(className)
    } else {
      node.classList.add(className)
    }
  }

  const observer = new IntersectionObserver(cb)
  observer.observe(observerNode)
}
