import { toggleClass } from '../lib/utils/sprinkles'

export default node => {
  const toggableSwitchElements = node.querySelectorAll('[data-toggable-switch-menu]')

  Array.from(toggableSwitchElements).forEach(toggableSwitch => {
    const toggableName = toggableSwitch.dataset.toggableSwitchMenu
    const toggableContent = node.querySelector('[data-toggable-content=' + toggableName + ']')
    const toggableEvent = toggableSwitch.dataset.toggableEvent || 'click'
    const toggableClass = toggableSwitch.dataset.toggableClass || 'isActive'

    toggableSwitch.addEventListener(toggableEvent, e => {
      toggleClass(toggableClass, toggableContent, node, e.target)
    })
  })
}
