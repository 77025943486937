export default node => {
  const className = node.dataset.className || 'isStickyActive'
  const scrollMarginClassName = node.dataset.scrollMarginClassName || 'withScrollMargin'
  const stickyParentClassName = node.dataset.stickyParentClassName || 'isGridStickyActive'

  const observerNode = document.querySelector(node.dataset.observerNode)
  const containerNode = document.querySelector(node.dataset.container)
  const parentNode = document.querySelector(node.dataset.stickyParent)

  const cb = entries => {
    if (entries[0].isIntersecting) {
      node.classList.remove(className)
      containerNode.classList.remove(scrollMarginClassName)
      parentNode.classList.remove(stickyParentClassName)
    } else {
      node.classList.add(className)
      containerNode.classList.add(scrollMarginClassName)
      parentNode.classList.add(stickyParentClassName)
    }
  }

  const observer = new IntersectionObserver(cb)
  observer.observe(observerNode)
}
