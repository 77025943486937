import { addClass, removeClass } from '../lib/utils/sprinkles'
import { debounce } from '../lib/utils/common'
import { transport } from '../lib/services'

const DELAY = 700

const updateIndicator = (node, value) => {
  if (value) {
    addClass('isActive', node)
  } else {
    removeClass('isActive', node)
  }
}

export default node => {
  const updateIndicatorHandler = () => {
    transport.SavedListings.showIndicator().then(response => {
      updateIndicator(node, response.show)
    })
  }

  const debouncedUpdateIndicatorHandler = debounce(updateIndicatorHandler, DELAY)

  global.addEventListener('transport.Listing.updateSavedStatusTask', debouncedUpdateIndicatorHandler)
  global.addEventListener('transport.Listing.updateSavedStatus', debouncedUpdateIndicatorHandler)

  return () => {
    global.removeEventListener('transport.Listing.updateSavedStatusTask', debouncedUpdateIndicatorHandler)
    global.removeEventListener('transport.Listing.updateSavedStatus', debouncedUpdateIndicatorHandler)
  }
}
