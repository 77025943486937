import { tns } from 'tiny-slider/src/tiny-slider'
import { isDefined } from '../lib/utils/common'
import replaceBrokenImage from './replaceBrokenImage'
import { isTouchScreen, isViewport } from '../lib/utils/viewport'
import { onKey } from '../lib/utils/dom'
import KEY from '../lib/constants/key'

export default node => {
  const container = node.querySelector('[data-simple-slider-items]')
  const controls = node.querySelector('[data-simple-slider-controls]')
  const wrapper = node.querySelector('[data-simple-slider-wrapper]')
  const showItems = +container.dataset.simpleSliderShow || 1
  const showTabletItems = +container.dataset.simpleSliderTabletShow || showItems
  const showMobileItems = +container.dataset.simpleSliderMobileShow || showItems
  const loop = container.dataset.simpleSliderLoop !== 'false'
  const withBrokenImage = isDefined(container.dataset.simpleSliderWithBrokenImage)
  const isLazyLoad = isDefined(container.dataset.simpleSliderLazyLoad)
  const navContainer = isViewport('desktop')
    ? node.querySelector('[data-simple-slider-dots-desktop]')
    : node.querySelector('[data-simple-slider-dots-tablet]')
  const prevButton = node.querySelector('[data-simple-slider-control-prev]')
  const nextButton = node.querySelector('[data-simple-slider-control-next]')
  const sliderSpeed = 250
  let liveRegion

  // set textContent in liveregion node for infinite sliders
  // TODO when in tiny-slider will be solve bug and text in node will be right this decision can be removed
  const getActiveSlidesIndexes = info => {
    const activeSlides = Array.from(info.slideItems)
      .filter(slide => slide.classList.contains('tns-slide-active'))
      .map(s => s.dataset.index)

    return activeSlides.length === info.items
      ? [activeSlides[0], activeSlides[activeSlides.length - 1]]
      : [activeSlides[1], activeSlides[activeSlides.length - 2]]
  }

  const setLiveRegionText = info => {
    const [from, to] = getActiveSlidesIndexes(info)
    liveRegion.textContent = from === to
      ? `Slide ${Number(from) + 1} of ${info.slideCount}`
      : `Slide from ${Number(from) + 1} to ${Number(to) + 1} of ${info.slideCount}`
  }

  const slider = tns({
    prevButton,
    nextButton,
    speed: sliderSpeed,
    container,
    loop,
    items: showItems,
    slideBy: 1,
    nav: navContainer || false,
    navContainer: navContainer || false,
    navAsThumbnails: navContainer || false,
    gutter: 20,
    lazyload: isLazyLoad,
    responsive: {
      320: {
        items: showMobileItems, controls, gutter: 15, edgePadding: 1
      }, 767: {
        items: showTabletItems, controls, gutter: 15, edgePadding: 1
      }, 1025: {
        items: showItems, gutter: 20, edgePadding: 0, controls: true
      }
    },
    onInit: e => {
      if (withBrokenImage) {
        Array.from(e.slideItems).forEach(slide => {
          const img = slide.querySelector('img')
          if (img) {
            replaceBrokenImage(slide.querySelector('img'))
          }
        })
      }

      liveRegion = node.querySelector('.tns-liveregion')
      if (loop) {
        setLiveRegionText(e)
      }

      e.nextButton.removeAttribute('tabindex')
      e.prevButton.removeAttribute('tabindex')
    }
  })

  const onTransitionStart = info => {
    if (loop) {
      setLiveRegionText(info)
    }
  }

  if (isTouchScreen() && wrapper) {
    const touchStub = document.createElement('div')
    touchStub.classList.add('SimpleSlider-touchStub')
    wrapper.appendChild(touchStub)
  }

  const goToSlide = direction => {
    slider.goTo(direction)

    setTimeout(() => {
      const { slideItems, index } = slider.getInfo()

      slideItems[index].querySelector('a').focus()
    }, sliderSpeed + 50)
  }

  const onKeyDownMoveSlider = e => {
    if (e.key !== KEY.LEFT && e.key !== KEY.RIGHT) {
      return
    }

    onKey(e, KEY.LEFT, () => goToSlide('prev'))
    onKey(e, KEY.RIGHT, () => goToSlide('next'))
  }

  const removerSlider = () => {
    if (slider) {
      slider.events.off('transitionStart', onTransitionStart)
      slider.destroy()
    }

    const touchStub = node.querySelector('.SimpleSlider-touchStub')

    if (touchStub) {
      touchStub.remove()
    }

    document.removeEventListener('turbolinks:before-cache', removerSlider)
    container.removeEventListener('keydown', onKeyDownMoveSlider)
  }

  document.addEventListener('turbolinks:before-cache', removerSlider)
  slider.events.on('transitionStart', onTransitionStart)
  container.addEventListener('keydown', onKeyDownMoveSlider)
}
