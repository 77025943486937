/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */
/* eslint no-console: 0 */

import { gaEvents } from '../lib/services'
import { transport } from '../lib/services/index'
import { toggleClass } from '../lib/utils/sprinkles'

const getAction = value => value ? 'subscribe' : 'unsubscribe'

const showFlash = containers => {
  containers.forEach(container => {
    const { subscriptionTogglerClass, subscriptionTogglerFadeOut } = container.dataset

    toggleClass(subscriptionTogglerClass, container)

    if (subscriptionTogglerFadeOut) { setTimeout(() => { toggleClass(subscriptionTogglerClass, container) }, 4000) }
  })
}

export default node => {
  const subscriptionTogglers = node.querySelectorAll('[data-subscription-toggler-button]')
  const subscriptionTogglerFlashes = node.querySelectorAll('[data-subscription-toggler-flash]')

  subscriptionTogglers.forEach(toggler => {
    const {
      subscriptionTogglerMethod,
      subscriptionTogglerUrl,
      subscriptionTogglerParams,
      subscriptionTogglerGaEventAction,
      subscriptionTogglerGaEventLabel
    } = toggler.dataset

    const data = JSON.parse(subscriptionTogglerParams)

    toggler.addEventListener('change', e => {
      const eventAction = subscriptionTogglerGaEventAction || getAction(e.target.checked)

      transport.Subscription
        .toggle(subscriptionTogglerUrl, subscriptionTogglerMethod, data)
        .then(showFlash.bind(null, subscriptionTogglerFlashes))
        .then(() => {
          if (subscriptionTogglerGaEventLabel) {
            gaEvents.UserProfile.toggler({ eventAction, eventLabel: subscriptionTogglerGaEventLabel })
          }
        })
        .catch(error => { console.log(error) })
    })
  })
}
