const FLASH_HIDE_TIMEOUT = 7000
const BEFORE_ANIMATION_DELAY = 1000

export default node => {
  setTimeout(() => { node.remove() }, FLASH_HIDE_TIMEOUT)

  if (node.dataset.animated) {
    node.style.display = 'none'
    setTimeout(() => { node.style.display = 'block' }, BEFORE_ANIMATION_DELAY)
  }

  node.querySelector('[data-flash-message-close]').addEventListener('click', () => {
    node.remove()
  })
}
