import KEY_CODE from './constants/keycode'
import PopupMenuLinks from './PopupMenuLinks'
import { isTouchDevice } from './utils/dom'

class MenuButton {
  constructor({ domNode, popupMenu, containerNode }) {
    this.domNode = domNode
    this.popupMenu = false
    this.hasFocus = false
    this.hasHover = false
    this.popupMenu = popupMenu
    this.containerNode = containerNode
  }

  init() {
    this.domNode.setAttribute('aria-haspopup', 'true')

    this.domNode.addEventListener('keydown', this.handleKeydown)
    this.domNode.addEventListener('click', this.handleClick)
    this.domNode.addEventListener('focus', this.handleFocus)
    this.domNode.addEventListener('blur', this.handleBlur)
    this.domNode.addEventListener('mouseover', this.handleMouseover)
    this.domNode.addEventListener('mouseout', this.handleMouseout)

    // initialize pop up menus
    if (this.popupMenu) {
      this.popupMenu = new PopupMenuLinks({
        domNode: this.popupMenu,
        controller: this,
        containerNode: this.containerNode
      })
      this.popupMenu.init()
    }
  }

  handleKeyUp = event => {
    if (event.keyCode === KEY_CODE.ESC && this.popupMenu) {
      this.hasHover = false
      this.popupMenu.close(true)
      document.removeEventListener('keyup', this.handleKeyUp)
    }
  }

  handleKeydown = event => {
    let isEventStopped = false

    switch (event.keyCode) {
    case KEY_CODE.SPACE:
    case KEY_CODE.RETURN:
    case KEY_CODE.DOWN:
      if (this.popupMenu) {
        this.popupMenu.open()
        this.popupMenu.setFocusToFirstItem()
      }
      isEventStopped = true
      break

    case KEY_CODE.UP:
      if (this.popupMenu) {
        this.popupMenu.open()
        this.popupMenu.setFocusToLastItem()
        isEventStopped = true
      }
      break

    default:
      break
    }

    if (isEventStopped) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  handleClick = () => {
    if (this.domNode.getAttribute('aria-expanded') === 'true') {
      this.popupMenu.close(true)
    } else {
      this.popupMenu.open()
      this.popupMenu.setFocusToFirstItem()
    }
  }

  handleFocus = () => {
    this.popupMenu.hasFocus = true
  }

  handleBlur = () => {
    this.popupMenu.hasFocus = false
  }

  handleMouseover = () => {
    if(isTouchDevice) { return }

    this.hasHover = true
    this.popupMenu.open()
    document.addEventListener('keyup', this.handleKeyUp)
  }

  handleMouseout = () => {
    if(isTouchDevice) { return }

    this.hasHover = false
    setTimeout(() => {
      this.popupMenu.close(false)
    }, 300)
    document.removeEventListener('keyup', this.handleKeyUp)
  }
}

export default MenuButton
