import { transport } from '../../lib/services'

export default node => {
  let lastSelectedValue = node.querySelector('option:checked').value
  const professionalId = node.dataset.professionalId

  node.addEventListener('change', (e) => {
    if (global.confirm('Do you really want to update status?')) {
      transport.Professional.updateStatus(professionalId, { status: e.target.value })
      lastSelectedValue = node.querySelector('option:checked').value
    } else {
      e.target.value = lastSelectedValue
    }

    return false
  })
}
