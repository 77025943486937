import { fetchFromSessionStorage } from '../lib/utils/browserStorage'
import { VIEWED_LISTING_ID } from '../lib/constants/storageKeys'

const viewedPriceClass = 'CardDesktop-price--viewedPrice'

export default node => {
  const listingId = Number(node.dataset.listingId)
  const viewedListings = fetchFromSessionStorage(VIEWED_LISTING_ID) || {}
  const isInViewedListings = viewedListings[listingId]
  const isListingViewed = node.classList.contains(viewedPriceClass)

  if (!isListingViewed && isInViewedListings) {
    node.classList.add(viewedPriceClass)
  }
}
