import { replaceImage } from '../lib/utils/dom'

export default node => {
  const imageUrl = node.dataset.replaceBrokenImageUrl

  const handlePictureError = event => {
    const sourceItems = node.parentNode.querySelectorAll('source') || []

    replaceImage(imageUrl)(event)

    Array.from(sourceItems).forEach(source => {
      source.srcset = imageUrl
      source.type = 'image/png'
    })
  }

  if (imageUrl) {
    node.addEventListener('error', handlePictureError)

    if (node.complete && node.naturalWidth === 0) {
      node.src = imageUrl
    }
  }
}
