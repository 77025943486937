import { toggleClass, removeClass } from '../lib/utils/sprinkles'

export default node => {
  const toggableSwitchElements = node.querySelectorAll('[data-toggable-modal-switch]')
  const toggableCloseElements = node.querySelectorAll('[data-toggable-modal-close]')
  const bodyElement = document.body
  const headerSearchBarElement = bodyElement.querySelector('#header-search-bar')
  const siteScrollerElement = bodyElement.querySelector('#site-scroller')
  const headerElement = bodyElement.querySelector('.Header')

  Array.from(toggableSwitchElements).forEach(toggableSwitch => {
    const toggableName = toggableSwitch.dataset.toggableModalSwitch
    const toggableContent = node.querySelector('[data-toggable-modal-content=' + toggableName + ']')

    toggableSwitch.addEventListener('click', () => {
      toggleClass('isActive', toggableSwitch, toggableContent)
      toggleClass('isModalActive', bodyElement, node)
      toggleClass('Header--withoutBorderBottom', headerElement)

      if(headerSearchBarElement) {
        toggleClass('Header-searchBar--visibilityHidden', headerSearchBarElement)
      }

      if(siteScrollerElement) {
        toggleClass('Site-scroller--visibilityHidden', siteScrollerElement)
      }

      let ariaExpandedValue = toggableSwitch.getAttribute('aria-expanded')

      if(ariaExpandedValue) {
        ariaExpandedValue = ariaExpandedValue === 'true' ? 'false' : 'true'
        toggableSwitch.setAttribute('aria-expanded', ariaExpandedValue)
      }
    })
  })

  Array.from(toggableCloseElements).forEach(toggableClose => {
    const toggableName = toggableClose.dataset.toggableModalClose
    const toggableContent = node.querySelector('[data-toggable-modal-content=' + toggableName + ']')
    const toggableSwitch = node.querySelector('[data-toggable-modal-switch=' + toggableName + ']')

    toggableClose.addEventListener('click', () => {
      removeClass('isActive', toggableSwitch, toggableContent)
      removeClass('isModalActive', bodyElement, node)
      removeClass('Header--withoutBorderBottom', headerElement)

      if(headerSearchBarElement) {
        removeClass('Header-searchBar--visibilityHidden', headerSearchBarElement)
      }

      if(siteScrollerElement) {
        removeClass('Site-scroller--visibilityHidden', siteScrollerElement)
      }
    })
  })
}
