import { keyboradDisplayEvent, hasParent, isFieldWithKeyboard } from '../lib/utils/dom'
import { isTouchScreen } from '../lib/utils/viewport'

export default node => {
  const keyboardChangeListener = keyboradDisplayEvent(
    e => {
      if (!hasParent(e.target, node) && isFieldWithKeyboard()) {
        node.classList.add('isHidden')
      }
    },
    () => { node.classList.remove('isHidden') }
  )

  if (isTouchScreen()) {
    global.document.addEventListener('focus', keyboardChangeListener, true)
  }
}
