import toggable from './toggable'
import toggableSideBar from './toggableSideBar'
import toggableModal from './toggableModal'
import simpleTabs from './simpleTabs'
import saveListing from './saveListing'
import linkElement from './linkElement'
import simpleSlider from './simpleSlider'
import textEllipsis from './textEllipsis'
import activateSticky from './activateSticky'
import saveSearch from './saveSearch'
import subscriptionToggler from './subscriptionToggler'
import searchHistoryUrl from './searchHistoryUrl'
import flashMessage from './flashMessage'
import pageNavigator from './pageNavigator'
import toggableText from './toggableText'
import viewMoreText from './viewMoreText'
import invokeAction from './invokeAction'
import authenticatedOnly from './authenticatedOnly'
import replaceBrokenImage from './replaceBrokenImage'
import hideOnMobileKeyboard from './hideOnMobileKeyboard'
import subscribeTo from './subscribeTo'
import savedListingsIndicator from './savedListingsIndicator'
import updateProfessionalStatus from './admin/updateProfessionalStatus'
import saveScrollPosition from './saveScrollPosition'
import sendAnalytics from './sendAnalytics'
import clearUserInfo from './clearUserInfo'
import addContactToFavorite from './addContactToFavorite'
import actionMenuPopup from './actionMenuPopup'
import activateStickyGrid from './activateStickyGrid'
import updateViewedListings from './updateViewedListings'
import stickyObserver from './stickyObserver'

export default {
  toggable: toggable,
  'toggable-side-bar': toggableSideBar,
  'toggable-modal': toggableModal,
  'action-menu-popup': actionMenuPopup,
  'simple-tabs': simpleTabs,
  'save-listing': saveListing,
  'link-element': linkElement,
  'simple-slider': simpleSlider,
  'text-ellipsis': textEllipsis,
  'activate-sticky': activateSticky,
  'activate-sticky-grid': activateStickyGrid,
  'save-search': saveSearch,
  'subscription-toggler': subscriptionToggler,
  'search-history-url': searchHistoryUrl,
  'flash-message': flashMessage,
  'page-navigator': pageNavigator,
  'toggable-text': toggableText,
  'view-more-text': viewMoreText,
  'invoke-action': invokeAction,
  'update-viewed-listings': updateViewedListings,
  'authenticated-only': authenticatedOnly,
  'replace-broken-image': replaceBrokenImage,
  'hide-on-mobile-keyboard': hideOnMobileKeyboard,
  'subscribe-to': subscribeTo,
  'update-professional-status': updateProfessionalStatus,
  'save-scroll-position': saveScrollPosition,
  'saved-listings-indicator': savedListingsIndicator,
  'send-analytics': sendAnalytics,
  'clear-user-info': clearUserInfo,
  'add-contact-to-favorite': addContactToFavorite,
  'sticky-observer': stickyObserver
}
