import { isRegistered, invokeAction } from '../lib/globalActions'
import { sessionStorage } from '../lib/services'

export default node => {
  const isAthenticated = node.dataset.authenticatedOnlyStatus
  const linkPath = node.getAttribute('href')

  if (isAthenticated === 'false') {
    node.addEventListener('click', e => {
      if (isRegistered('AuthModal', 'openModal')) {
        e.preventDefault()
        sessionStorage.saveToSessionStorage('afterAuthenticatePath', linkPath)
        invokeAction('AuthModal', 'openModal')
      }
    })
  }
}
