export default node => {
  node.style.position = 'relative'
  const content = node.querySelector('[data-view-more-text-content]')
  const maxLength = parseInt(content.dataset.viewMoreTextMaxLength, 10)
  const viewMoreButton = node.querySelector('[data-view-more-text-button]')
  const fullText = content.innerHTML

  if (fullText.length > maxLength) {
    content.innerHTML = fullText.substr(0, maxLength)
    viewMoreButton.dataset.toggableTextFull = fullText
    viewMoreButton.classList.add('isShown')

    viewMoreButton.addEventListener('click', e => {
      e.stopPropagation()
      e.preventDefault()

      viewMoreButton.classList.remove('isShown')
      content.innerHTML = fullText
    })
  }
}
