import { debounce } from '../lib/utils/common'
import { offsetTop, outerHeight, smoothScrollTo } from '../lib/utils/dom'

const OFFSET_DELTA = 5

const debouncedScrollHandler = debounce((pageNavigatorScrollItems, pageNavigatorMenuItems, topPadding) => {
  const currentScrollPosition = global.pageYOffset || document.documentElement.scrollTop

  const currentSection = Array.from(pageNavigatorScrollItems).find(element => (
    element.offsetTop + outerHeight(element) > currentScrollPosition + topPadding
  ))

  if (currentSection) {
    const currentMenuItem = Array.from(pageNavigatorMenuItems).find(element => (
      currentSection.id === element.getAttribute('href').replace('#', '')
    ))

    Array.from(pageNavigatorMenuItems).forEach(item => { item.classList.remove('isActive') })
    currentMenuItem.classList.add('isActive')
  }
}, 100)

export default node => {
  const pageNavigatorMenu = node.querySelector('[data-page-navigator-menu]')
  const pageNavigatorMenuItems = pageNavigatorMenu.querySelectorAll('[data-page-navigator-menu-item]')
  const pageNavigatorScrollItems = node.querySelectorAll('[data-page-navigator-scroll-item]')
  const pageNavigatorMenuHeight = outerHeight(pageNavigatorMenu)

  global.addEventListener('scroll', () => {
    debouncedScrollHandler(pageNavigatorScrollItems, pageNavigatorMenuItems, pageNavigatorMenuHeight)
  })

  pageNavigatorMenuItems.forEach(menuItem => {
    menuItem.addEventListener('click', e => {
      const scrollToId = e.target.getAttribute('href')
      const scrollToElement = node.querySelector(scrollToId)

      if (scrollToElement) { smoothScrollTo(offsetTop(scrollToElement) - pageNavigatorMenuHeight + OFFSET_DELTA) }
      e.preventDefault()
    })
  })
}
