const actions = {
  addClass: node => name => {
    node.classList.add(name)
  },

  removeClass: node => name => {
    node.classList.remove(name)
  }
}

export default node => {
  const events = node.dataset.events

  Object.entries(JSON.parse(events)).forEach(([eventName, commands]) => {
    global.addEventListener(eventName, () => {
      Object.entries(commands).forEach(([actionName, arg]) => {
        actions[actionName](node)(arg)
      })
    })
  })
}
