/* eslint no-shadow: 0 */
/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */

import { transport, gaEvents } from '../lib/services'
import { toggleClass, toggleContent } from '../lib/utils/sprinkles'
import { isDefined } from '../lib/utils/common'

const deleteSearch = (element, { search }) => {
  const eventCategory = (`${search.listingClass}_search`).toLowerCase()

  return transport.Search.destroy(search.id).then(response => {
    if(!response.success) {
      return
    }

    gaEvents.Srp.deleteSearch(eventCategory)

    element.dataset.method = 'create'

    toggleClass('isActive', element)
    toggleContent('Save', 'Saved', element)
  })
}

const saveSearch = (element, data) => {
  const eventCategory = (`${data.search.listingClass}_search`).toLowerCase()

  return transport.Search.save(data).then(response => {
    if(isDefined(response.success) && !response.success) {
      return
    }

    gaEvents.Srp.saveSearch(eventCategory)

    element.dataset.method = 'delete'
    element.dataset.saveSearchId = response.search.id

    toggleClass('isActive', element)
    toggleContent('Save', 'Saved', element)
  })
}

export default node => {
  const saveSearchButton = node.querySelector('[data-save-search-button]')

  saveSearchButton.addEventListener('click', e => {
    e.preventDefault()
    e.stopPropagation()

    const method = saveSearchButton.dataset.method
    const { saveSearchId, saveSearchQuery, saveSearchListingClass } = saveSearchButton.dataset

    if (method === 'delete') {
      const data = {
        search: {
          listingClass: saveSearchListingClass,
          id: saveSearchId
        }
      }

      deleteSearch(saveSearchButton, data)
    } else if (method === 'create') {
      const data = {
        search: {
          listingClass: saveSearchListingClass,
          query: JSON.parse(saveSearchQuery)
        }
      }

      saveSearch(saveSearchButton, data)
    }
  })
}
