export const toggleClass = (className, ...toggableElements) => {
  toggableElements.forEach(el => {
    el.classList.toggle(className)
  })
}

export const removeClass = (className, ...elements) => {
  elements.forEach(el => {
    el.classList.remove(className)
  })
}

export const addClass = (className, ...elements) => {
  elements.forEach(el => {
    el.classList.add(className)
  })
}

export const toggleContent = (variant1, variant2, toggableElement) => {
  const text = toggableElement.textContent.trim()

  if (text === variant1) {
    toggableElement.textContent = variant2
  } else {
    toggableElement.textContent = variant1
  }
}
