import KEY_CODE from './constants/keycode'

class MenuItemLinks {
  constructor(domNode, menuObj) {
    this.domNode = domNode
    this.menu = menuObj
  }

  init() {
    this.domNode.tabIndex = -1

    if (!this.domNode.getAttribute('role')) {
      this.domNode.setAttribute('role', 'menuitem')
    }

    this.domNode.addEventListener('keydown', this.handleKeydown)
    this.domNode.addEventListener('click', this.handleClick)
    this.domNode.addEventListener('focus', this.handleFocus)
    this.domNode.addEventListener('blur', this.handleBlur)
    this.domNode.addEventListener('mouseover', this.handleMouseover)
    this.domNode.addEventListener('mouseout', this.handleMouseout)
  }

  /* EVENT HANDLERS */

  handleKeydown = event => {
    let isEventStopped = false
    let char = event.key

    function isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S/)
    }

    const isSkippedKey = event.ctrlKey
      || event.altKey
      || event.metaKey
      || (event.keyCode === KEY_CODE.SPACE)
      || (event.keyCode === KEY_CODE.RETURN)

    if (isSkippedKey) {
      return
    }

    if (event.shiftKey) {
      if (isPrintableCharacter(char)) {
        this.menu.setFocusByFirstCharacter(this, char)
        isEventStopped = true
      }

      if (event.keyCode === KEY_CODE.TAB) {
        this.menu.setFocusToController()
        this.menu.close(true)
      }
    } else {
      switch (event.keyCode) {
      case KEY_CODE.ESC:
        this.menu.setFocusToController()
        this.menu.close(true)
        isEventStopped = true
        break

      case KEY_CODE.UP:
        this.menu.setFocusToPreviousItem(this)
        isEventStopped = true
        break

      case KEY_CODE.DOWN:
        this.menu.setFocusToNextItem(this)
        isEventStopped = true
        break

      case KEY_CODE.HOME:
      case KEY_CODE.PAGEUP:
        this.menu.setFocusToFirstItem()
        isEventStopped = true
        break

      case KEY_CODE.END:
      case KEY_CODE.PAGEDOWN:
        this.menu.setFocusToLastItem()
        isEventStopped = true
        break

      case KEY_CODE.TAB:
        this.menu.setFocusToController()
        this.menu.close(true)
        break

      default:
        if (isPrintableCharacter(char)) {
          this.menu.setFocusByFirstCharacter(this, char)
        }
        break
      }
    }

    if (isEventStopped) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  handleClick = () => {
    this.menu.setFocusToController()
    this.menu.close(true)
  }

  handleFocus = () => {
    this.menu.hasFocus = true
  }

  handleBlur = () => {
    this.menu.hasFocus = false
    setTimeout(() => {
      this.menu.close(false)
    }, 300)
  }

  handleMouseover = () => {
    this.menu.hasHover = true
    this.menu.open()
  }

  handleMouseout = () => {
    this.menu.hasHover = false
    setTimeout(() => {
      this.menu.close(false)
    }, 300)
  }
}

export default MenuItemLinks
