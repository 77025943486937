/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */
/* eslint no-console: 0 */

import { gaEvents, transport } from '../lib/services/index'
import { addClass, removeClass } from '../lib/utils/sprinkles'
import { listingCaptchaKey } from '../lib/config/recaptcha'

export default node => {
  let isAllowedRequest = true
  const saveListingButton = node.querySelector('[data-save-listing-button]')
  const { delegateTo, id } = saveListingButton.dataset

  const updateSavedStatusHandler = listing => {
    const { error } = listing

    if (error) {
      return
    }

    const { _links: { save_action: { url, method } } } = listing
    const eventLabel = listing.saved ? 'save' : 'unsave'

    // In case of delegate we need to update all items that
    // satisfy the identifier, not only those which catched the delegated event.
    if (delegateTo) {
      const delegates = document.querySelectorAll(delegateTo)

      Array.from(delegates).forEach(delegate => {
        Array.from(
          delegate.querySelectorAll(`[data-delegate-id="${saveListingButton.dataset.delegateId}"]`)
        ).forEach(el => {
          if (listing.saved) {
            addClass('isActive', el)
          } else {
            removeClass('isActive', el)
          }
        })
      })
    } else if (listing.saved) {
      addClass('isActive', saveListingButton)
    } else {
      removeClass('isActive', saveListingButton)
    }

    saveListingButton.dataset.url = url
    saveListingButton.dataset.method = method

    gaEvents.Hdp.saveListing(eventLabel)
  }

  const setSavedStatusHandler = ({ detail: { listingId } }) => {
    if (Number(id) === listingId) {
      addClass('isActive', saveListingButton)
      saveListingButton.textContent = 'Saved'
    }
  }

  const verifyAndUpdateStatus = ({ detail: { data } }) => {
    if (Number(id) === data.id) {
      updateSavedStatusHandler(data)
    }
  }

  const makeRequest = e => {
    const { url, method } = saveListingButton.dataset

    e.preventDefault()
    e.stopPropagation()

    if (isAllowedRequest) {
      isAllowedRequest = false
      transport.Listing.updateSavedStatus({
        url,
        method,
        captchaKey: listingCaptchaKey(saveListingButton.dataset)
      })
        .then(() => { isAllowedRequest = true })
        .catch(error => { console.log(error) })
    }
  }

  const delegateRequest = e => {
    const element = e.target.closest('[data-save-listing-button]')
    if (element && element.dataset && element.dataset.delegateId &&
        saveListingButton.dataset.delegateId === element.dataset.delegateId) {
      makeRequest(e)
    }
  }

  global.addEventListener('transport.Listing.updateSavedStatusTask', verifyAndUpdateStatus)
  global.addEventListener('transport.Listing.updateSavedStatus', verifyAndUpdateStatus)
  global.addEventListener('send-contact', setSavedStatusHandler)

  // We need to delegate click events in case the card was cloned,
  // for example by tiny-slider
  if (delegateTo) {
    const delegates = document.querySelectorAll(delegateTo)

    Array.from(delegates).forEach(delegate => {
      delegate.addEventListener('click', delegateRequest)
    })
  } else {
    saveListingButton.addEventListener('click', makeRequest)
  }

  return () => {
    global.removeEventListener('transport.Listing.updateSavedStatusTask', verifyAndUpdateStatus)
    global.removeEventListener('transport.Listing.updateSavedStatus', verifyAndUpdateStatus)
    global.removeEventListener('send-contact', setSavedStatusHandler)

    if (delegateTo) {
      const delegates = document.querySelectorAll(delegateTo)

      Array.from(delegates).forEach(delegate => {
        delegate.removeEventListener('click', delegateRequest)
      })
    } else {
      saveListingButton.removeEventListener('click', makeRequest)
    }
  }
}
