import { isServer } from '../../lib/utils/common'
import { isHidden } from '../../lib/utils/dom'

export const isViewport = name => {
  if (isServer()) {
    return true
  }

  const sensor = global.document.querySelector(`[data-viewport="${name}"]`)
  return !!sensor && !isHidden(sensor)
}

export const isTouchScreen = () => (
  isViewport('mobile') || isViewport('tablet')
)
