import { invokeAction } from '../lib/globalActions'

export default node => {
  const module = node.dataset.invokeActionModule
  const actionName = node.dataset.invokeActionName
  const actionParams = node.dataset.invokeActionParams || {}
  const event = node.dataset.invokeActionEvent || 'click'

  node.addEventListener(event, e => {
    e.preventDefault()

    invokeAction(module, actionName, actionParams)
  })
}
