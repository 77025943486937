import MenuItemLinks from './MenuItemLinks'

class PopupMenuLinks {
  /**
   * @desc
   *      Wrapper object for a simple popup menu (without nested submenus)
   *
   * @param {HTMLElement} domNode
   *      The DOM element node that serves as the popup menu container. Each
   *      child element of domNode that represents a menuitem must have a
   *      'role' attribute with value 'menuitem'.
   *
   * @param {MenuButton} controller
   *      The object that is a wrapper for the DOM element that controls the
   *      menu, e.g. a button element, with an 'aria-controls' attribute that
   *      references this menu's domNode. See MenuButton.js
   *
   *       The controller object is expected to have the following properties:
   *       1. domNode: The controller object's DOM element node, needed for
   *          retrieving positioning information.
   *       2. hasHover: boolean that indicates whether the controller object's
   *          domNode has responded to a mouseover event with no subsequent
   *          mouseout event having occurred.
   *
   * @param {HTMLElement} containerNode
   */

  constructor({ domNode, controller, containerNode }) {
    let msgPrefix = 'PopupMenuLinks constructor argument domNode '

    // Check whether domNode is a DOM element
    if (!domNode instanceof Element) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.')
    }

    // Check whether domNode has child elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.')
    }

    // Check whether domNode descendant elements have A elements
    let childElement = domNode.firstElementChild
    while (childElement) {
      let menuitem = childElement.firstElementChild
      if (menuitem && menuitem.tagName !== 'A') {
        throw new Error(msgPrefix + 'has descendant elements that are not A elements.')
      }
      childElement = childElement.nextElementSibling
    }

    this.domNode = domNode
    this.controller = controller
    this.containerNode = containerNode

    // see PopupMenuLinks init method
    this.menuItems = []
    this.firstChars = []

    this.firstItem = null
    this.lastItem = null

    // see MenuItemLinks handleFocus, handleBlur
    this.hasFocus = false
    this.hasHover = false
  }


  /**
   * @method init
   * @desc
   *    Add domNode event listeners for mouseover and mouseout. Traverse
   *    domNode children to configure each menuitem and populate menuItems
   *    array. Initialize firstItem and lastItem properties.
   */

  init() {
    let childElement
    let menuElement
    let menuItem
    let textContent
    let numItems
    let label

    // Configure the domNode itself
    this.domNode.tabIndex = -1

    this.domNode.setAttribute('role', 'menu')

    if (!this.domNode.getAttribute('aria-labelledby')
      && !this.domNode.getAttribute('aria-label')
      && !this.domNode.getAttribute('title')
    ) {
      label = this.controller.domNode.innerHTML
      this.domNode.setAttribute('aria-label', label)
    }

    this.domNode.addEventListener('mouseover', this.handleMouseover)
    this.domNode.addEventListener('mouseout', this.handleMouseout)

    // Traverse the element children of domNode: configure each with
    // menuitem role behavior and store reference in menuItems array.
    childElement = this.domNode.firstElementChild

    while (childElement) {
      menuElement = childElement.firstElementChild

      if (menuElement && menuElement.tagName === 'A') {
        menuItem = new MenuItemLinks(menuElement, this)
        menuItem.init()
        this.menuItems.push(menuItem)
        textContent = menuElement.textContent.trim()
        this.firstChars.push(textContent.substring(0, 1).toLowerCase())
      }
      childElement = childElement.nextElementSibling
    }

    // Use populated menuItems array to initialize firstItem and lastItem.
    numItems = this.menuItems.length
    if (numItems > 0) {
      this.firstItem = this.menuItems[0]
      this.lastItem = this.menuItems[numItems - 1]
    }
  }

  /* EVENT HANDLERS */

  handleMouseover = () => {
    this.hasHover = true
  }

  handleMouseout = () => {
    this.hasHover = false
    setTimeout(() => this.close(false), 300)
  }

  /* FOCUS MANAGEMENT METHODS */

  setFocusToController() {
    this.controller.domNode.focus()
  }

  setFocusToFirstItem() {
    this.firstItem.domNode.focus()
  }

  setFocusToLastItem() {
    this.lastItem.domNode.focus()
  }

  setFocusToPreviousItem(currentItem) {
    let index

    if (currentItem === this.firstItem) {
      this.lastItem.domNode.focus()
    } else {
      index = this.menuItems.indexOf(currentItem)
      this.menuItems[index - 1].domNode.focus()
    }
  }

  setFocusToNextItem(currentItem) {
    let index

    if (currentItem === this.lastItem) {
      this.firstItem.domNode.focus()
    } else {
      index = this.menuItems.indexOf(currentItem)
      this.menuItems[index + 1].domNode.focus()
    }
  }

  setFocusByFirstCharacter(currentItem, char) {
    let start
    let index
    let normalizedChar = char.toLowerCase()

    // Get start index for search based on position of currentItem
    start = this.menuItems.indexOf(currentItem) + 1
    if (start === this.menuItems.length) {
      start = 0
    }

    // Check remaining slots in the menu
    index = this.getIndexFirstChars(start, normalizedChar)

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = this.getIndexFirstChars(0, normalizedChar)
    }

    // If match was found...
    if (index > -1) {
      this.menuItems[index].domNode.focus()
    }
  }

  getIndexFirstChars(startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i + 1) {
      if (char === this.firstChars[i]) {
        return i
      }
    }
    return -1
  }

  /* MENU DISPLAY METHODS */

  open = () => {
    this.containerNode.classList.add('isActive')
    this.controller.domNode.setAttribute('aria-expanded', 'true')
  }

  close = force => {
    if (force || (!this.hasFocus && !this.hasHover && !this.controller.hasHover)) {
      this.containerNode.classList.remove('isActive')
      this.controller.domNode.removeAttribute('aria-expanded')
    }
  }
}


export default PopupMenuLinks
