import { fetchFromSessionStorage } from '../lib/utils/browserStorage'
import { SEARCH_HISTORY_URL } from '../lib/constants'

export default node => {
  const linkElements = Array.from(node.querySelectorAll('[data-search-history-url="link"]'))
  const currentSearchUrl = fetchFromSessionStorage(SEARCH_HISTORY_URL)

  if (currentSearchUrl) {
    linkElements.forEach(el => {
      el.setAttribute('href', currentSearchUrl)
    })
  } else {
    node.classList.add('isTransparent')
  }
}
