import { saveToSessionStorage } from '../lib/utils/browserStorage'
import { globalScrollPosition } from '../lib/utils/dom'

const getScrollPosition = nodeId => {
  if (nodeId) {
    return document.getElementById(nodeId).scrollTop
  }
  const { y: position } = globalScrollPosition()
  return position
}

export default node => {
  const { storageKey, scrollerNodeId } = node.dataset
  const currentUrl = global.location.toString()

  if (storageKey) {
    const storePosition = () => {
      const position = getScrollPosition(scrollerNodeId)
      saveToSessionStorage(storageKey, { url: currentUrl, position, scrollerNodeId })
    }

    node.addEventListener('click', storePosition)
  }
}
